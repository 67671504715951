<template>
  <pivotOptions
    :includePivot.sync="pivot.includePivot"
    :pivotFirst.sync="pivot.pivotFirst"
    :pivotName.sync="pivot.pivotName"
    :rowFields.sync="pivot.rowFields"
    :columnFields.sync="pivot.columnFields"
    :dataFields.sync="pivot.dataFields"
    :excludeFields.sync="pivot.excludeFields"
    :filterFields.sync="pivot.filterFields"
    :filteredHeaders="filteredHeaders"
  ></pivotOptions>
</template>
<script>
import pivotOptions from '@components/reports/pivotOptions'
export default {
  model: {
    prop: 'pivot',
  },
  props: {
    pivot: {
      type: Object,
      default: {
        includePivot: false,
        pivotFirst: true,
        pivotName: '',
        rowFields: [],
        columnFields: [],
        dataFields: [],
        excludeFields: [],
        filterFields: [],
      },
    },
    filteredHeaders: Array,
  },
  components: {
    pivotOptions,
  },
  filters: {},
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped>
</style>