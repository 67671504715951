export const TimesheetReports = [
  {
    ID: -49,
    Title: '49 - Employee Timesheet',
    currentUser: true,
    currentUserDivision: true,
    currentUserAndLeaveProjectDivision: true,
    filterDivision: [],
    Query: {
      sortProperty: 'Date',
      sortDirection: 'asc',
      searchText: '',
      includeFields: [
        'FullName',
        'Date',
        'ProjectNumber',
        'ProjectDescription',
        'ActivityCodeDescription',
        'Note',
        'Hours',
      ],
      users: [],
      supervisors: [],
      userDivision: [],
      projectDivision: [],
      status: [],
      activityCode: [],
      project: [],
      entity: [],
      projectDetails: [],
      projectDetailsPrefix: [],
      departmentStrategy: [],
      queryName: '49 - Employee Timesheet',
      queryIsModified: false,
      includePivot: true,
      pivotFirst: false,
      pivotName: 'Activity Totals',
      rowFields: ['FullName', 'ProjectDescription', 'ActivityCodeDescription'],
      columnFields: [],
      filterFields: [],
      dataFields: ['Hours|sum'],
    },
  },
  {
    ID: -51,
    Title: '51 - Leaveslip Time Analysis By Employee - Summary',
    currentUser: false,
    currentUserDivision: false,
    // currentUserAndLeaveProjectDivision: true,
    filterDivision: [],
    Query: {
      sortProperty: 'FullName',
      sortDirection: 'asc',
      searchText: '',
      includeFields: ['FullName', 'ActivityCodeDescription', 'Hours'],
      users: [],
      supervisors: [],
      userDivision: [],
      projectDivision: [1002],
      status: [],
      activityCode: [],
      project: [],
      entity: [],
      projectDetails: [],
      projectDetailsPrefix: [],
      departmentStrategy: [],
      queryName: '51 - Leaveslip Time Analysis By Employee - Summary',
      queryIsModified: false,
      includePivot: true,
      pivotFirst: true,
      pivotName: 'Leaveslip Analysis',
      rowFields: ['FullName'],
      columnFields: ['ActivityCodeDescription'],
      filterFields: [],
      dataFields: ['Hours|sum'],
    },
  },
  {
    ID: -55,
    Title:
      '55 - Project Time Analysis by dept Strategy, ProjectDetail, Activity',
    currentUser: false,
    currentUserDivision: false,
    currentUserAndLeaveProjectDivision: true,
    filterDivision: [],
    Query: {
      sortProperty: 'LastName',
      sortDirection: 'asc',
      searchText: '',
      includeFields: [
        'ProjectNumber',
        'ProjectDescription',
        'Date',
        'FullName',
        'DepartmentStrategy',
        'DepartmentStrategyDescription',
        'ProjectDetails',
        'ActivityCode',
        'ActivityCodeDescription',
        'Note',
        'Hours',
      ],
      users: [],
      supervisors: [],
      userDivision: [],
      projectDivision: [],
      status: [],
      activityCode: [],
      project: [],
      entity: [],
      projectDetails: [],
      projectDetailsPrefix: [],
      departmentStrategy: [],
      queryName:
        '55 - Project Time Analysis by dept Strategy, ProjectDetail, Activity',
      queryIsModified: false,
      includePivot: true,
      pivotFirst: true,
      pivotName: 'Project Time Analysis',
      rowFields: ['ProjectNumber', 'ActivityCodeDescription'],
      columnFields: [],
      filterFields: [],
      dataFields: ['Hours|sum'],
    },
  },
  {
    ID: -60,
    Title: '60 - Project Monthly Recap by ProjectDetail',
    currentUser: false,
    currentUserDivision: false,
    currentUserProjectDivision: false,
    currentUserAndLeaveProjectDivision: true,
    filterDivision: [],
    Query: {
      sortProperty: 'ProjectDescription',
      sortDirection: 'asc',
      searchText: '',
      includeFields: [
        'FullName',
        'ProjectNumber',
        'ProjectDescription',
        'ProjectDetails',
        'Date',
        'Hours',
      ],
      users: [],
      supervisors: [],
      userDivision: [],
      projectDivision: [],
      status: [
        'Unsubmitted',
        'Awaiting Verification',
        'Awaiting Supervisor Approval',
        'Rejected',
        'Approved',
      ],
      activityCode: [],
      project: [],
      entity: [],
      projectDetails: [],
      projectDetailsPrefix: [],
      departmentStrategy: [],
      queryName: '60 - Project Monthly Recap by ProjectDetail',
      queryIsModified: false,
      includePivot: true,
      pivotFirst: true,
      pivotName: 'Project Monthly Recap',
      rowFields: [],
      columnFields: ['Date'],
      filterFields: [],
      dataFields: ['Hours|sum'],
      excludeFields: ['FullName'],
    },
  },
  {
    ID: -109,
    Title: '109 - Employee SubDivision Time Summary',
    currentUser: false,
    currentUserDivision: false,
    currentUserAndLeaveProjectDivision: true,
    filterDivision: [],
    Query: {
      sortProperty: 'LastName',
      sortDirection: 'asc',
      searchText: '',
      includeFields: ['FullName', 'SubDivision', 'Hours'],
      users: [],
      supervisors: [],
      userDivision: [],
      projectDivision: [],
      status: [],
      activityCode: [],
      project: [],
      entity: [],
      projectDetails: [],
      projectDetailsPrefix: [],
      departmentStrategy: [],
      queryName: '109 - Employee SubDivision Time Summary',
      queryIsModified: false,
      includePivot: true,
      pivotFirst: true,
      pivotName: 'Time Summary',
      rowFields: ['FullName', 'SubDivision'],
      columnFields: [],
      filterFields: [],
      dataFields: ['Hours|sum', 'Hours|pcnt'],
    },
  },
  {
    ID: -108,
    Title: '108 - Time Analysis by Entity',
    currentUser: false,
    currentUserDivision: false,
    currentUserProjectDivision: true,
    currentUserAndLeaveProjectDivision: false,
    filterDivision: [],
    Query: {
      sortProperty: 'SubDivision',
      sortDirection: 'asc',
      searchText: '',
      includeFields: [
        'SubDivision',
        'EntityName',
        'ProjectDescription',
        'ProjectNumber',
        'Hours',
        'Days',
      ],
      users: [],
      supervisors: [],
      userDivision: [],
      projectDivision: [],
      status: [],
      activityCode: [],
      project: [],
      entity: [],
      projectDetails: [],
      projectDetailsPrefix: [],
      departmentStrategy: [],
      queryName: '108 - Time Analysis by Entity',
      queryIsModified: false,
      includePivot: true,
      pivotFirst: true,
      pivotName: 'Time Summary',
      rowFields: ['SubDivision'],
      columnFields: [],
      filterFields: [],
      dataFields: ['Hours|sum', 'Days|sum'],
    },
  },
  {
    ID: -105,
    Title: '105 - Project Detail Recap by Employee',
    currentUser: false,
    currentUserDivision: false,
    currentUserAndLeaveProjectDivision: true,
    filterDivision: [],
    Query: {
      sortProperty: 'LastName',
      sortDirection: 'asc',
      searchText: '',
      includeFields: ['FullName', 'ProjectDetails', 'Hours'],
      users: [],
      supervisors: [],
      userDivision: [],
      projectDivision: [],
      status: [],
      activityCode: [],
      project: [],
      entity: [],
      projectDetails: [],
      projectDetailsPrefix: [],
      departmentStrategy: [],
      queryName: '105 - Project Detail Recap by Employee',
      queryIsModified: false,
      includePivot: true,
      pivotFirst: true,
      pivotName: 'Project Detail Recap',
      rowFields: ['FullName'],
      columnFields: ['ProjectDetails'],
      filterFields: [],
      dataFields: ['Hours|sum'],
    },
  },
  {
    ID: -199,
    Title: '199 - Timesheets not yet Approved',
    currentUser: false,
    currentUserDivision: false,
    currentUserAndLeaveProjectDivision: true,
    filterDivision: [],
    Query: {
      sortProperty: 'LastName',
      sortDirection: 'asc',
      searchText: '',
      includeFields: ['FullName', 'Status', 'StartDate', 'EndDate', 'Hours'],
      users: [],
      supervisors: [],
      userDivision: [],
      projectDivision: [],
      status: [
        'No Time Entered',
        'Unsubmitted',
        'Awaiting Verification',
        'Awaiting Supervisor Approval',
        'Rejected',
      ],
      activityCode: [],
      project: [],
      entity: [],
      projectDetails: [],
      projectDetailsPrefix: [],
      departmentStrategy: [],
      queryName: '199 - Timesheets not yet Approved',
      queryIsModified: false,
      includePivot: true,
      pivotFirst: true,
      pivotName: 'Timesheets Pending',
      rowFields: ['StartDate', 'EndDate', 'Status', 'FullName'],
      columnFields: [],
      filterFields: [],
      dataFields: ['Hours|sum'],
    },
  },
  {
    ID: -112,
    Title: '112 - Time Analysis by Dept Strategy and Supervisor',
    currentUser: false,
    currentUserDivision: false,
    currentUserAndLeaveProjectDivision: true,
    filterDivision: [],
    Query: {
      sortProperty: 'FullName',
      sortDirection: 'asc',
      searchText: '',
      includeFields: [
        'Supervisor',
        'FullName',
        'ProjectNumber',
        'ProjectDescription',
        'DepartmentStrategy',
        'DepartmentStrategyDescription',
        'Hours',
      ],
      users: [],
      supervisors: [],
      userDivision: [],
      projectDivision: [],
      status: [],
      activityCode: [],
      project: [],
      entity: [],
      projectDetails: [],
      projectDetailsPrefix: [],
      departmentStrategy: [],
      queryName: '112 - Time Analysis by Dept Strategy and Supervisor',
      queryIsModified: false,
      includePivot: true,
      pivotFirst: true,
      pivotName: 'Time Analysis',
      rowFields: ['FullName'],
      columnFields: [],
      filterFields: ['Supervisor'],
      dataFields: ['Hours|sum'],
    },
  },
  {
    ID: -117,
    Title: '117 - Leaveslip Time Analysis by Employee - Detail',
    currentUser: false,
    currentUserDivision: false,
    // currentUserAndLeaveProjectDivision: true,
    filterDivision: [],
    Query: {
      sortProperty: 'FullName',
      sortDirection: 'asc',
      searchText: '',
      includeFields: [
        'FullName',
        'Date',
        'ActivityCode',
        'ActivityCodeDescription',
        'Note',
        'Hours',
      ],
      users: [],
      supervisors: [],
      userDivision: [],
      projectDivision: [1002],
      status: [],
      activityCode: [],
      project: [],
      entity: [],
      projectDetails: [],
      projectDetailsPrefix: [],
      departmentStrategy: [],
      queryName: '117 - Leaveslip Time Analysis by Employee - Detail',
      queryIsModified: false,
      includePivot: true,
      pivotFirst: true,
      pivotName: 'Leaveslip Time Analysis',
      rowFields: ['FullName'],
      columnFields: [],
      filterFields: [],
      dataFields: ['Hours|sum'],
    },
  },
]
export const ProjectReports = [
  {
    ID: -210,
    Title: '210 - Auditor Progress Report',
    currentUser: false,
    currentUserProjectDivision: true,
    filterDivision: [],
    Query: {
      sortProperty: 'LeadAuditor',
      sortDirection: 'asc',
      searchText: '',
      selectedCategories: ['Dates', 'Findings'],
      includeFields: [
        'LeadAuditor',
        'ProjectNumber',
        'ProjectDescription',
        'FromDate',
        'DateType',
        'FindingsType',
        'FindingsValue',
      ],
      projectDivision: [],
      project: [],
      entity: [],
      projectDetails: [],
      projectDetailsPrefix: [],
      departmentStrategy: [],
      projectStatus: ['Active'],
      entityStatus: ['Active'],
      subEntityStatus: ['Active'],
      leads: [],
      users: [],
      licenseTypes: [],
      entityTypes: [],
      dateTypes: [],
      findingTypes: [],
      collectionTypes: [],
      reportedValueTypes: [],
      reviewTypes: [],
      activityCode: [],
      queryName: '210 - Auditor Progress Report',
      queryIsModified: false,
      includePivot: true,
      pivotFirst: true,
      pivotName: 'Auditor Progress',
      rowFields: ['LeadAuditor', 'ProjectNumber'],
      columnFields: ['DateType'],
      filterFields: [],
      dataFields: ['FromDate|max'],
    },
  },
  {
    ID: -34,
    Title: '34 - Projects By Division and Subdivision',
    currentUser: false,
    currentUserProjectDivision: true,
    clearDateFields: true,
    filterDivision: [],
    Query: {
      sortProperty: 'ProjectNumber',
      sortDirection: 'asc',
      searchText: '',
      selectedCategories: [],
      includeFields: [
        'SubDivision',
        'ProjectNumber',
        'ProjectDescription',
        'ProjectStatus',
      ],
      projectDivision: [],
      project: [],
      entity: [],
      projectDetails: [],
      projectDetailsPrefix: [],
      departmentStrategy: [],
      projectStatus: ['Active', 'Inactive'],
      entityStatus: ['Active', 'Inactive'],
      subEntityStatus: ['Active', 'Inactive'],
      leads: [],
      users: [],
      licenseTypes: [],
      entityTypes: [],
      dateTypes: [],
      findingTypes: [],
      collectionTypes: [],
      reportedValueTypes: [],
      reviewTypes: [],
      activityCode: [],
      queryName: '34 - Projects By Division and Subdivision',
      queryIsModified: false,
      includePivot: true,
      pivotFirst: true,
      pivotName: 'Project Status by SubDivision',
      rowFields: ['SubDivision'],
      columnFields: [],
      filterFields: [],
      dataFields: [],
    },
  },
  {
    ID: -13,
    Title: '13 - Projects Status by Entity Name',
    currentUser: false,
    currentUserProjectDivision: true,
    clearDateFields: true,
    filterDivision: [],
    Query: {
      sortProperty: 'ProjectNumber',
      sortDirection: 'asc',
      searchText: '',
      selectedCategories: [],
      includeFields: [
        'EntityName',
        'ProjectNumber',
        'ProjectDescription',
        'ProjectStatus',
      ],
      projectDivision: [],
      project: [],
      entity: [],
      projectDetails: [],
      projectDetailsPrefix: [],
      departmentStrategy: [],
      projectStatus: ['Active', 'Inactive'],
      entityStatus: ['Active', 'Inactive'],
      subEntityStatus: ['Active', 'Inactive'],
      leads: [],
      users: [],
      licenseTypes: [],
      entityTypes: [],
      dateTypes: [],
      findingTypes: [],
      collectionTypes: [],
      reportedValueTypes: [],
      reviewTypes: [],
      activityCode: [],
      queryName: '13 - Projects By Entity',
      queryIsModified: false,
      includePivot: true,
      pivotFirst: true,
      pivotName: 'Project Status by Entity Name',
      rowFields: ['EntityName'],
      columnFields: [],
      filterFields: [],
      dataFields: [],
    },
  },
  {
    ID: -143,
    Title: '143 - Activity Report',
    currentUser: false,
    currentUserProjectDivision: true,
    filterDivision: [5, 6],
    Query: {
      sortProperty: 'DateType',
      sortDirection: 'asc',
      searchText: '',
      selectedCategories: ['Dates', 'Findings'],
      includeFields: ['DateType', 'EntityName', 'LicenseType', 'FindingsValue'],
      projectDivision: [],
      project: [],
      entity: [],
      projectDetails: [],
      projectDetailsPrefix: [],
      departmentStrategy: [],
      projectStatus: ['Active'],
      entityStatus: ['Active'],
      subEntityStatus: ['Active'],
      leads: [],
      users: [],
      licenseTypes: [],
      entityTypes: [],
      dateTypes: [],
      findingTypes: [],
      collectionTypes: [],
      reportedValueTypes: [],
      reviewTypes: [],
      activityCode: [],
      queryName: '143 - Activity Report',
      queryIsModified: false,
      includePivot: true,
      pivotFirst: true,
      pivotName: 'Activity Report',
      rowFields: ['DateType'],
      columnFields: [],
      filterFields: [],
      dataFields: ['FindingsValue|sum'],
    },
  },
  {
    ID: -164,
    Title: '164 - Engagement Status',
    currentUser: false,
    currentUserProjectDivision: true,
    filterDivision: [],
    Query: {
      sortProperty: 'DateType',
      sortDirection: 'asc',
      searchText: '',
      selectedCategories: ['Dates'],
      includeFields: ['EntityType', 'EntityName', 'DateType', 'FromDate'],
      projectDivision: [],
      project: [],
      entity: [],
      projectDetails: [],
      projectDetailsPrefix: [],
      departmentStrategy: [],
      projectStatus: ['Active'],
      entityStatus: ['Active'],
      subEntityStatus: ['Active'],
      leads: [],
      users: [],
      licenseTypes: [],
      entityTypes: [],
      dateTypes: [],
      findingTypes: [],
      collectionTypes: [],
      reportedValueTypes: [],
      reviewTypes: [],
      activityCode: [],
      queryName: '164 - Engagement Status',
      queryIsModified: false,
      includePivot: true,
      pivotFirst: true,
      pivotName: 'Engagement Status',
      rowFields: ['EntityType', 'EntityName', 'DateType'],
      columnFields: [],
      filterFields: [],
      dataFields: ['FromDate|max'],
    },
  },
]
export const FinancialReports = [
  {
    ID: -68,
    Title: '68 - Forms Processed',
    Query: {
      sortProperty: 'EntityName',
      sortDirection: 'asc',
      searchText: '',
      includeFields: [
        'CountyID',
        'EntityName',
        'ReportYear',
        'DateReceived',
        'FinancialActivityName',
        'OnTime',
      ],
      entity: [],
      includedWithEntity: [],
      cpaEntity: [],
      entityStatus: ['Active'],
      entityTypes: [],
      reportRequired: [],
      onTime: [],
      reportYear: [],
      counties: [],
      censusReports: [],
      financialActivity: [],
      queryName: '68 - Forms Processed',
      queryIsModified: false,
      includePivot: false,
      pivotFirst: false,
      pivotName: 'Forms Processed',
      rowFields: [],
      columnFields: [],
      filterFields: [],
      dataFields: [],
    },
  },
]
export const ExciseExceptionReports = [
  {
    ID: -10,
    Title: 'Review of Sales Transactions',
    Query: {
      sortProperty: ['VendorCustomer', 'ID'],
      sortDirection: ['asc', 'asc'],
      groupByProperty: 'VendorCustomer',
      groupByDirection: 'asc',
      searchText: '',
      includeFields: [
        'InvoiceDate',
        'DocumentNumber',
        'VendorCustomer',
        'Description',
        'TaxableAmount',
        'TaxRate',
        'TaxPaid',
        'TaxDue',
        'DeliveredToCountyID',
        'Issue',
      ],
      queryName: 'Review of Sales Transactions',
      queryIsModified: false,
      pivotOptions: {
        columnFields: [],
        dataFields: [],
        excludeFields: [],
        filterFields: [],
        includePivot: false,
        pivotFirst: false,
        pivotName: '',
        rowFields: [],
      },
      projectionTypeID: [1],
      status: ['Active'],
      stratumStatus: ['Active'],
      taxTypeID: [1, 2],
      transactionTypeID: [1],
    },
  },
  {
    ID: -11,
    Title: 'Review of Purchase Transactions',
    Query: {
      sortProperty: ['VendorCustomer', 'ID'],
      sortDirection: ['asc', 'asc'],
      groupByProperty: 'VendorCustomer',
      groupByDirection: 'asc',
      searchText: '',
      includeFields: [
        'InvoiceDate',
        'DocumentNumber',
        'VendorCustomer',
        'Description',
        'TaxableAmount',
        'TaxRate',
        'TaxPaid',
        'TaxDue',
        'DeliveredToCountyID',
        'Issue',
      ],
      queryName: 'Review of Purchase Transactions',
      queryIsModified: false,
      pivotOptions: {
        columnFields: [],
        dataFields: [],
        excludeFields: [],
        filterFields: [],
        includePivot: false,
        pivotFirst: false,
        pivotName: '',
        rowFields: [],
      },
      projectionTypeID: [1],
      status: ['Active'],
      stratumStatus: ['Active'],
      taxTypeID: [1, 2],
      transactionTypeID: [2],
    },
  },
  {
    ID: -12,
    Title: 'Review of Lodging Transactions',
    Query: {
      sortProperty: ['VendorCustomer', 'ID'],
      sortDirection: ['asc', 'asc'],
      groupByProperty: 'VendorCustomer',
      groupByDirection: 'asc',
      searchText: '',
      includeFields: [
        'InvoiceDate',
        'DocumentNumber',
        'VendorCustomer',
        'Description',
        'TaxableAmount',
        'TaxRate',
        'TaxPaid',
        'TaxDue',
        'DeliveredToCountyID',
        'Issue',
      ],
      queryName: 'Review of Lodging Transactions',
      queryIsModified: false,
      pivotOptions: {
        columnFields: [],
        dataFields: [],
        excludeFields: [],
        filterFields: [],
        includePivot: false,
        pivotFirst: false,
        pivotName: '',
        rowFields: [],
      },
      projectionTypeID: [1],
      status: ['Active'],
      stratumStatus: ['Active'],
      taxTypeID: [3],
      transactionTypeID: [],
    },
  },
  {
    ID: -13,
    Title: 'Review of Lodging Sales Tax Transactions',
    Query: {
      sortProperty: ['VendorCustomer', 'ID'],
      sortDirection: ['asc', 'asc'],
      groupByProperty: 'VendorCustomer',
      groupByDirection: 'asc',
      searchText: '',
      includeFields: [
        'InvoiceDate',
        'DocumentNumber',
        'VendorCustomer',
        'Description',
        'TaxableAmount',
        'TaxRate',
        'TaxPaid',
        'TaxDue',
        'DeliveredToCountyID',
        'Issue',
      ],
      queryName: 'Review of Lodging Sales Tax Transactions',
      queryIsModified: false,
      pivotOptions: {
        columnFields: [],
        dataFields: [],
        excludeFields: [],
        filterFields: [],
        includePivot: false,
        pivotFirst: false,
        pivotName: '',
        rowFields: [],
      },
      projectionTypeID: [1],
      status: ['Active'],
      stratumStatus: ['Active'],
      taxTypeID: [1],
      transactionTypeID: [3],
    },
  },
]

const StaticReports = {
  TimesheetReports: TimesheetReports,
  ProjectReports: ProjectReports,
  FinancialReports: FinancialReports,
  ExciseExceptionReports,
}

export default StaticReports
