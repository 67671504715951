<template>
  <v-container fluid>
    <!-- ADHOC PROJECT REPORTS -->
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                AdHoc Financial Reports
                <span class="pl-3">
                  <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                      <v-btn
                        color="white mr-4"
                        v-on="on"
                        fab
                        @click.stop="callSync()"
                        depressed
                        x-small
                        :loading="isSyncing"
                      >
                        <v-icon color="primary">mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>Sync From Online Census</span>
                  </v-tooltip>
                </span>
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row>
                    <v-col sm="12" md="3">
                      <BaseDatePickerWithText
                        label="Start Date"
                        clearable
                        v-model="startDate"
                      ></BaseDatePickerWithText>
                    </v-col>
                    <v-col sm="12" md="3">
                      <BaseDatePickerWithText
                        label="End Date"
                        clearable
                        v-model="endDate"
                      ></BaseDatePickerWithText>
                    </v-col>

                    <v-col sm="12" md="3">
                      <v-text-field
                        v-model="searchText"
                        prepend-inner-icon="mdi-text-box-search"
                        label="Search"
                        hint="Entity, SubEntity, Project, Strategy, Details"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="3" class="mt-8">
                      <v-autocomplete
                        v-model="selectedFields"
                        :items="availableHeaders"
                        item-text="text"
                        item-value="value"
                        label="Select Columns"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:selection="{item, index}">
                          <v-chip
                            small
                            v-if="index < 5"
                            close
                            @click:close="selectedFields.splice(index, 1)"
                            >{{ item.text }}</v-chip
                          >
                          <span v-if="index === 5" class="grey--text caption"
                            >(+{{ selectedFields.length - 5 }} others)</span
                          >
                        </template>
                        <template v-slot:prepend>
                          <reorderSelectedFields
                            v-model="selectedFields"
                          ></reorderSelectedFields>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col sm="12" md="2">
                      <h4
                        @click="toggleFilters"
                        class="pointer primary--text"
                        >{{ filterText }}</h4
                      >
                    </v-col>
                  </v-row>
                  <v-row v-if="filters">
                    <v-col sm="12" md="3">
                      <v-select
                        v-model="entityStatus"
                        :items="statusList"
                        label="Entity Status"
                        chips
                        deletable-chips
                        small-chips
                        multiple
                        clearable
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-select
                        v-model="reportRequired"
                        :items="requiredList"
                        label="Report Required"
                        chips
                        deletable-chips
                        small-chips
                        multiple
                        clearable
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-select
                        v-model="onTime"
                        :items="onTimeList"
                        label="On Time"
                        chips
                        deletable-chips
                        small-chips
                        multiple
                        clearable
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-select
                        v-model="reportYear"
                        :items="reportYears"
                        label="Report Year"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-autocomplete
                        v-model="counties"
                        :items="luCounty"
                        label="County"
                        item-text="Name"
                        item-value="ID"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-select
                        v-model="censusReports"
                        :items="availableLuCensusReport"
                        item-text="displayValue"
                        item-value="ID"
                        label="Census Report"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-select
                        v-model="financialActivity"
                        :items="luFinancialActivity"
                        item-text="FinancialActivityName"
                        item-value="FinancialActivityID"
                        label="FinancialActivity"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-select>
                    </v-col>

                    <v-col sm="12" md="3">
                      <v-autocomplete
                        v-model="entityTypes"
                        :items="availableEntityTypes"
                        item-text="Description"
                        item-value="ID"
                        label="Entity Type"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      ></v-autocomplete>
                    </v-col>

                    <v-col sm="12" md="3">
                      <v-autocomplete
                        v-model="entity"
                        :items="entityList"
                        :search-input.sync="entitySearchText"
                        :loading="entitySearchIsLoading"
                        placeholder="Start typing to Search"
                        item-text="FullName"
                        item-value="ID"
                        label="Entity"
                        cache-items
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              >No results for search query</v-list-item-title
                            >
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-autocomplete
                        v-model="includedWithEntity"
                        :items="includedWithEntityList"
                        :search-input.sync="includedWithEntitySearchText"
                        :loading="includedWithEntitySearchIsLoading"
                        placeholder="Start typing to Search"
                        item-text="FullName"
                        item-value="ID"
                        label="Included With"
                        cache-items
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              >No results for search query</v-list-item-title
                            >
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-autocomplete
                        v-model="cpaEntity"
                        :items="cpaEntityList"
                        :search-input.sync="cpaEntitySearchText"
                        :loading="cpaEntitySearchIsLoading"
                        placeholder="Start typing to Search"
                        item-text="FullName"
                        item-value="ID"
                        label="CPA Entity"
                        cache-items
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              >No results for search query</v-list-item-title
                            >
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <pivotOptions
                    :pivot="pivot"
                    :filteredHeaders="filteredHeaders"
                  ></pivotOptions>
                </v-card-text>
                <template>
                  <v-data-table
                    :headers="filteredHeaders"
                    :items="entries"
                    :server-items-length="total"
                    :loading="loading"
                    :sort-by.sync="pagination.sortBy"
                    :sort-desc.sync="pagination.descending"
                    :page.sync="pagination.page"
                    :items-per-page.sync="pagination.rowsPerPage"
                    :footer-props="{
                      itemsPerPageOptions: [5, 10, 25, 50],
                    }"
                    class="pt-4"
                    dense
                  ></v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <!-- GENERATE ADHOC -->
    <v-row justify="end">
      <v-col sm="auto">
        <v-btn @click="getAdhoc" color="primary">
          <v-icon left>mdi-file-chart</v-icon>Generate AdHoc
        </v-btn>
      </v-col>
    </v-row>
    <!-- SAVED QUERIES -->
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel2" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                Saved Queries
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-form @submit.prevent>
                    <v-row justify="space-between">
                      <v-col sm="12" md="6">
                        <v-select
                          :items="availableSavedQueries"
                          item-text="Title"
                          item-value="ID"
                          label="Saved Queries"
                          v-model="selectedQueryID"
                          @change="selectQuery"
                          clearable
                        >
                          <template slot="item" slot-scope="data">
                            <v-row>
                              <v-col
                                v-if="data.item.ID && data.item.ID > 0"
                                sm="auto"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{on}">
                                    <v-btn color="primary" v-on="on" icon>
                                      <v-icon
                                        class="clickable"
                                        color="primary"
                                        @click="deleteConfirm(data.item)"
                                        >mdi-trash-can</v-icon
                                      >
                                    </v-btn>
                                  </template>
                                  <span>Delete Query</span>
                                </v-tooltip>
                              </v-col>
                              <v-col sm="auto" class="mt-4">{{
                                data.item.Title
                              }}</v-col>
                            </v-row>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col sm="12" md="3" class="mt-4">
                        <v-btn color="primary" @click="dialog = true">
                          <v-icon left>mdi-content-save</v-icon>Save New Query
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-dialog v-model="dialog" width="450">
                      <v-card class="elevation-3">
                        <v-card-title class="primary">
                          <h3 class="hpHeading white--text"
                            >Enter New Queries</h3
                          >
                        </v-card-title>
                        <v-card-text class="pa-4">
                          <v-row>
                            <v-col sm="12">
                              <v-text-field
                                label="Query Name"
                                v-model="savedQueriesTitle"
                              ></v-text-field>
                            </v-col>
                            <v-col sm="12">
                              <v-card-actions>
                                <v-btn color="primary" @click="saveQuery"
                                  >Accept</v-btn
                                >
                                <v-btn
                                  outlined
                                  color="primary"
                                  @click="dialog = !dialog"
                                  >Cancel</v-btn
                                >
                              </v-card-actions>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                    <v-dialog
                      persistent
                      v-model="deleteDialog"
                      scrollable
                      width="350"
                    >
                      <v-card class="elevation-3">
                        <v-card-title class="primary">
                          <h3 class="hpHeading white--text">Confirm?</h3>
                        </v-card-title>
                        <v-card-text class="pa-4">
                          <v-row>
                            <v-col sm="12"
                              >Are you sure you want to delete this
                              query?</v-col
                            >
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            color="primary"
                            @click="deleteDialog = !deleteDialog"
                            >Cancel</v-btn
                          >
                          <v-btn
                            @click="deleteQueries"
                            outlined
                            color="primary"
                            class="white--text"
                            >Delete</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-form>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- SCRIPTS -->
<script>
import pivotOptions from '@components/reports/pivotOptionsObject'
import reorderSelectedFields from '@components/reports/reorderSelectedFields'
import dayjs from 'dayjs'
import download from 'downloadjs'
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import {debounce, range} from 'lodash'
import SavedQueriesFinancialReport from '@classes/SavedQueriesFinancialReport'
import {FinancialReports} from '@defaults/StaticReports'
import draggable from 'vuedraggable'

export default {
  name: 'Reports',
  page: {
    title: 'Financial Reports',
  },
  components: {
    draggable,
    pivotOptions,
    reorderSelectedFields,
  },
  data: () => ({
    valid: true,
    panel: [0, 1],
    panel2: [0, 1],
    savedQueriesTitle: '',
    savedQueries: [],
    deleteQuery: {},
    dialog: false,
    deleteDialog: false,
    isSyncing: false,
    searchText: '',
    entries: [],
    startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
    //report specific
    entity: [],
    includedWithEntity: [],
    cpaEntity: [],
    entityStatus: ['Active'],
    statusList: ['Active', 'Inactive'],
    entityTypes: [],
    reportRequired: [],
    requiredList: ['Yes', 'No'],
    onTime: [],
    onTimeList: ['On Time', 'Late'],
    reportYear: [],
    counties: [],
    censusReports: [],
    financialActivity: [],
    //
    queryName: '',
    queryIsModified: false,
    pivot: {
      includePivot: false,
      pivotFirst: true,
      pivotName: '',
      rowFields: [],
      columnFields: [],
      excludeFields: [],
      dataFields: [],
      filterFields: [],
    },
    entitySearchText: '',
    entitySearchCount: 0,
    entityList: [],
    includedWithEntitySearchText: '',
    includedWithEntitySearchCount: 0,
    includedWithEntityList: [],
    cpaEntitySearchText: '',
    cpaEntitySearchCount: 0,
    cpaEntityList: [],
    filters: true,
    filterText: '-Hide Filters-',
    modalText: '',

    total: 0,
    searchCount: 0,
    pagination: {
      page: 1,
      sortBy: 'DateReceived',
      descending: false,
      rowsPerPage: 25,
    },
    selectedFields: [],
    selectedQueryID: 0,
    staticReports: FinancialReports,
    dataFieldModal: false,
    selectedDataField: '',
    selectedDataType: '',
    selectedCategories: [],
    headers: [
      {
        text: 'Financial Report ID',
        align: 'left',
        sortable: 'true',
        value: 'FinancialReportID',
        visible: false,
      },
      {
        text: 'Entity ID',
        align: 'left',
        sortable: 'true',
        value: 'EntityID',
        visible: false,
      },
      {
        text: 'Entity',
        align: 'left',
        sortable: 'true',
        value: 'EntityName',
        visible: true,
      },
      {
        text: 'Entity Short Name',
        align: 'left',
        sortable: 'true',
        value: 'EntityShortName',
        visible: false,
      },
      {
        text: 'Entity Type',
        align: 'left',
        sortable: 'true',
        value: 'EntityType',
        visible: true,
      },
      {
        text: 'Entity Status',
        align: 'left',
        sortable: 'true',
        value: 'EntityStatus',
        visible: false,
      },
      {
        text: 'Entity Phone',
        align: 'left',
        sortable: 'true',
        value: 'EntityPhone',
        visible: false,
      },
      {
        text: 'Entity Address',
        align: 'left',
        sortable: 'true',
        value: 'EntityAddress',
        visible: false,
      },
      {
        text: 'Entity City',
        align: 'left',
        sortable: 'true',
        value: 'EntityCity',
        visible: false,
      },
      {
        text: 'Entity State',
        align: 'left',
        sortable: 'true',
        value: 'EntityState',
        visible: false,
      },
      {
        text: 'Entity Zip',
        align: 'left',
        sortable: 'true',
        value: 'EntityZip',
        visible: false,
      },
      {
        text: 'Entity Physical Address',
        align: 'left',
        sortable: 'true',
        value: 'EntityPhysicalAddress',
        visible: false,
      },
      {
        text: 'Entity Physical City',
        align: 'left',
        sortable: 'true',
        value: 'EntityPhysicalCity',
        visible: false,
      },
      {
        text: 'Entity Physical State',
        align: 'left',
        sortable: 'true',
        value: 'EntityPhysicalState',
        visible: false,
      },
      {
        text: 'Entity Physical Zip',
        align: 'left',
        sortable: 'true',
        value: 'EntityPhysicalZip',
        visible: false,
      },
      {
        text: 'Report Required',
        align: 'left',
        sortable: 'true',
        value: 'ReportRequired',
        visible: true,
      },
      {
        text: 'Date Received',
        align: 'left',
        sortable: 'true',
        value: 'DateReceived',
        visible: true,
      },
      {
        text: 'Date Entered',
        align: 'left',
        sortable: 'true',
        value: 'DateEntered',
        visible: false,
      },
      {
        text: 'On Time',
        align: 'left',
        sortable: 'true',
        value: 'OnTime',
        visible: true,
      },
      {
        text: 'Report Year',
        align: 'left',
        sortable: 'true',
        value: 'ReportYear',
        visible: true,
      },
      {
        text: 'Reporting Year',
        align: 'left',
        sortable: 'true',
        value: 'ReportingYear',
        visible: false,
      },
      {
        text: 'Fiscal Month Year End',
        align: 'left',
        sortable: 'true',
        value: 'FiscalMonthYearEnd',
        visible: false,
      },
      {
        text: 'Origination Date',
        align: 'left',
        sortable: 'true',
        value: 'OriginationDate',
        visible: false,
      },
      {
        text: 'Dissolved Date',
        align: 'left',
        sortable: 'true',
        value: 'DissolvedDate',
        visible: false,
      },
      {
        text: 'Census Type',
        align: 'left',
        sortable: 'true',
        value: 'CensusType',
        visible: false,
      },
      {
        text: 'Census Description',
        align: 'left',
        sortable: 'true',
        value: 'CensusDescription',
        visible: false,
      },
      {
        text: 'CensusNumber',
        align: 'left',
        sortable: 'true',
        value: 'CensusNumber',
        visible: false,
      },
      {
        text: 'County ID',
        align: 'left',
        sortable: 'true',
        value: 'CountyID',
        visible: false,
      },
      {
        text: 'County',
        align: 'left',
        sortable: 'true',
        value: 'County',
        visible: true,
      },
      {
        text: 'County Population',
        align: 'left',
        sortable: 'true',
        value: 'CountyPopulation',
        visible: false,
      },
      {
        text: 'FinancialActivityID',
        align: 'left',
        sortable: 'true',
        value: 'FinancialActivityID',
        visible: false,
      },
      {
        text: 'Financial Activity',
        align: 'left',
        sortable: 'true',
        value: 'FinancialActivityName',
        visible: true,
      },
      {
        text: 'Audit Included With ID',
        align: 'left',
        sortable: 'true',
        value: 'AuditIncludedWithID',
        visible: false,
      },
      {
        text: 'Audit Included With',
        align: 'left',
        sortable: 'true',
        value: 'AuditIncludedWith',
        visible: true,
      },
      {
        text: 'CPA Entity ID',
        align: 'left',
        sortable: 'true',
        value: 'CPAEntityID',
        visible: false,
      },
      {
        text: 'CPA Entity',
        align: 'left',
        sortable: 'true',
        value: 'CPAEntity',
        visible: true,
      },
    ],
  }),
  created() {
    //todo: run financial report sync?
    this.selectedFields = this.availableHeaders
      .filter((h) => h.visible)
      .map((h) => h.value)
    this.loadLuCounty()
    this.loadLuPFEntityType()
    this.loadLuFinancialActivity()
    this.loadLuCensusReport()
    this.searchReports()
    this.getQueriesSaved()
    this.bounce(this)
  },
  watch: {
    pagination: {
      handler() {
        this.bounce(this)
      },
      deep: true,
    },
    selectedFields: {
      handler(nval, oval) {
        this.headers.forEach((item) => {
          if (nval.includes(item.value)) {
            item.visible = true
          } else {
            item.visible = false
          }
        })
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
      deep: true,
    },
    //make sure we don't have selectedFields that are not available any longer
    availableHeaders: {
      handler(nval, oval) {
        let selectedFields = this.selectedFields.filter((item) => {
          return nval.find((v) => v.value == item)
        })
        this.selectedFields = selectedFields
      },
      deep: true,
    },
    availableEntityTypes: {
      handler(nval, oval) {
        let selectedFields = this.entityTypes.filter((item) => {
          return nval.find((v) => v.ID == item)
        })
        this.entityTypes = selectedFields
      },
    },
    //for each search parameter we need to call bounce to search once the user has made changes
    searchText: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    startDate: {
      handler() {
        this.bounce(this)
      },
    },
    endDate: {
      handler() {
        this.bounce(this)
      },
    },
    entity: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    includedWithEntity: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    cpaEntity: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    entityStatus: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    entityTypes: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    reportRequired: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    onTime: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    reportYear: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    counties: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    censusReports: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    financialActivity: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },

    //autocomplete search watches

    entitySearchText: {
      handler() {
        this.entitySearchBounce(this)
      },
    },
    includedWithEntitySearchText: {
      handler() {
        this.includedWithEntitySearchBounce(this)
      },
    },
    cpaEntitySearchText: {
      handler() {
        this.cpaEntitySearchBounce(this)
      },
    },
    currentUser: {
      handler(nval) {
        if (nval) {
          this.getQueriesSaved()
        }
      },
    },
  },
  computed: {
    ...authComputed,
    luPFEntityType: get('luPfEntityType/luPFEntityType'),
    luCounty: get('luCounty/luCounty'),
    luCensusReport: get('luCensusReport/luCensusReport'),
    luFinancialActivity: get('luFinancialActivity/luFinancialActivity'),
    loading() {
      return this.searchCount > 0
    },
    includedWithEntitySearchIsLoading() {
      return this.includedWithEntitySearchCount > 0
    },
    entitySearchIsLoading() {
      return this.entitySearchCount > 0
    },
    cpaEntitySearchIsLoading() {
      return this.cpaEntitySearchCount > 0
    },
    availableHeaders() {
      let list = this.headers.filter((item) => {
        return true
      })
      return list
    },
    filteredHeaders: {
      get() {
        let list = this.availableHeaders.filter((h) => h.visible)
        list.sort((a, b) => {
          return (
            this.selectedFields.indexOf(a.value) -
            this.selectedFields.indexOf(b.value)
          )
        })
        return list
      },
    },
    selectedQuery() {
      return this.availableSavedQueries.find(
        (q) => q.ID == this.selectedQueryID
      )
    },
    selectedQueryParams() {
      if (this.selectedQuery) {
        return JSON.parse(this.selectedQuery.Query)
      }
    },
    searchParams() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        sortProperty: this.pagination.sortBy,
        sortDirection: this.pagination.descending ? 'desc' : 'asc',
        searchText: this.searchText,
        includeFields: this.selectedFields,
        entity: this.entity,
        includedWithEntity: this.includedWithEntity,
        cpaEntity: this.cpaEntity,
        entityStatus: this.entityStatus,
        entityTypes: this.entityTypes,
        reportRequired: this.reportRequired,
        onTime: this.onTime,
        reportYear: this.reportYear,
        counties: this.counties,
        censusReports: this.censusReports,
        financialActivity: this.financialActivity,
        queryName: this.queryName,
        queryIsModified: this.queryIsModified,
        includePivot: this.pivot.includePivot,
        pivotFirst: this.pivot.pivotFirst,
        pivotName: this.pivot.pivotName,
        rowFields: this.pivot.rowFields,
        columnFields: this.pivot.columnFields,
        dataFields: this.pivot.dataFields,
        excludeFields: this.pivot.excludeFields,
        filterFields: this.pivot.filterFields,
      }
    },
    entitySearchParams() {
      if (!!this.entitySearchText) {
        return {
          searchText: this.entitySearchText,
          entityStatus: this.entityStatus,
          division: [4],
          entityType: this.entityType,
        }
      } else {
        return null
      }
    },
    includedWithEntitySearchParams() {
      if (!!this.includedWithEntitySearchText) {
        return {
          searchText: this.entitySearchText,
          entityStatus: this.entityStatus,
          division: [4],
        }
      } else {
        return null
      }
    },
    cpaEntitySearchParams() {
      if (!!this.cpaEntitySearchText) {
        return {
          searchText: this.entitySearchText,
          entityStatus: this.entityStatus,
          division: [4],
          entityType: [46],
        }
      } else {
        return null
      }
    },
    reportYears() {
      let yearList = _.range(
        dayjs().add(-10, 'years').year(),
        dayjs().add(6, 'years').year(),
        1
      )
      return yearList
    },
    availableLuCensusReport() {
      let list = this.luCensusReport.filter((item) => {
        return true
      })
      return list.map((item) => {
        return {
          ...item,
          displayValue: item.Name + ' - ' + item.Description,
        }
      })
    },
    availableEntityTypes() {
      let list = this.luPFEntityType.filter((item) => {
        return true
      })
      list.sort((a, b) => {
        let textField = 'Description'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return list
    },
    availableSavedQueries() {
      let list = this.savedQueries
      let list2 = []
      if (this.staticReports.length > 0) {
        this.staticReports.forEach((r) => {
          let query = r.Query
          list2.push({Title: r.Title, ID: r.ID, Query: JSON.stringify(query)})
        })
        list2.sort((a, b) => b.ID - a.ID)
      }
      return list2.concat(list)
    },
  },
  methods: {
    loadLuCounty: call('luCounty/loadLuCounty'),
    loadLuPFEntityType: call('luPfEntityType/loadLuPFEntityType'),
    loadLuCensusReport: call('luCensusReport/loadLuCensusReport'),
    loadLuFinancialActivity: call(
      'luFinancialActivity/loadLuFinancialActivity'
    ),
    createSavedQueriesFinancialReport: call(
      'savedQueriesFinancialReport/createSavedQueriesFinancialReport'
    ),
    deleteSavedQueriesFinancialReport: call(
      'savedQueriesFinancialReport/deleteSavedQueriesFinancialReport'
    ),
    handleError: call('errors/handleError'),
    toastMessage: call('messages/toastMessage'),
    toggleFilters() {
      this.filters = !this.filters
      if (this.filters) {
        this.filterText = '-Hide Filters-'
      } else {
        this.filterText = '-Show Filters-'
      }
    },
    getQueriesSaved() {
      if (this.currentUser) {
        const url = `SavedQueriesFinancialReport/User/${this.currentUser.ID}`
        this.$axios.get(url).then(
          (res) => {
            this.savedQueries = res.data
          },
          (error) => {
            this.handleError(error)
            // this.error = error
            // this.displayError = true
            console.error(error)
          }
        )
      }
    },
    deleteConfirm(item) {
      this.deleteQuery = item
      this.deleteDialog = true
    },
    deleteQueries() {
      let index = this.savedQueries.findIndex(
        (q) => q.ID == this.deleteQuery.ID
      )
      this.deleteSavedQueriesFinancialReport(this.deleteQuery).then(
        (res) => {
          this.savedQueries.splice(index, 1)
        },
        (err) => {}
      )
      this.deleteDialog = false
    },
    searchReports() {
      if (!this.pagination.sortBy) {
        this.pagination.sortBy = 'DateReceived'
        this.pagination.descending = false
      }
      if (Array.isArray(this.pagination.sortBy)) {
        if (this.pagination.sortBy.length > 0) {
          this.pagination.sortBy = this.pagination.sortBy[0]
        } else {
          this.pagination.sortBy = 'DateReceived'
        }
        this.pagination.descending = false
      }
      if (!this.searchText) {
        this.searchText = ''
      }
      this.searchCount++
      const url = `AdHocFinancialReport?pageNum=${
        this.pagination.page - 1
      }&pageSize=${this.pagination.rowsPerPage}&search=${JSON.stringify(
        this.searchParams
      )}`
      this.$axios.get(url).then(
        (res) => {
          this.pagination.rowsPerPage = res.data.Page.Size
          this.pagination.page = res.data.Page.Number + 1
          this.total = res.data.Page.TotalElements
          this.entries = res.data.Entries
          this.searchCount--
        },
        (error) => {
          this.handleError(error)
          // this.error = error
          // this.displayError = true
          console.error(error)
          this.searchCount--
        }
      )
    },
    searchEntities() {
      if (!!this.entitySearchParams) {
        const url = `AuditEntity/Lookup?search=${JSON.stringify(
          this.entitySearchParams
        )}`
        this.entitySearchCount++
        this.$axios.get(url).then(
          (res) => {
            this.entityList = res.data.map((item) => {
              return {
                ...item,
              }
            })
            this.entitySearchCount--
          },
          (error) => {
            this.handleError(error)
            console.error(error)
            this.entitySearchCount--
          }
        )
      } else this.entityList = []
    },
    searchIncludedWithEntities() {
      if (!!this.includedWithEntitySearchParams) {
        const url = `AuditEntity/Lookup?search=${JSON.stringify(
          this.includedWithEntitySearchParams
        )}`
        this.includedWithEntitySearchCount++
        this.$axios.get(url).then(
          (res) => {
            this.includedWithEntityList = res.data.map((item) => {
              return {
                ...item,
              }
            })
            this.includedWithEntitySearchCount--
          },
          (error) => {
            this.handleError(error)
            console.error(error)
            this.includedWithEntitySearchCount--
          }
        )
      } else this.includedWithEntityList = []
    },
    searchCpaEntities() {
      if (!!this.cpaEntitySearchParams) {
        const url = `AuditEntity/Lookup?search=${JSON.stringify(
          this.cpaEntitySearchParams
        )}`
        this.cpaEntitySearchCount++
        this.$axios.get(url).then(
          (res) => {
            this.cpaEntityList = res.data.map((item) => {
              return {
                ...item,
              }
            })
            this.cpaEntitySearchCount--
          },
          (error) => {
            this.handleError(error)
            console.error(error)
            this.cpaEntitySearchCount--
          }
        )
      } else this.cpaEntityList = []
    },

    getAdhoc(type) {
      let headers = {responseType: 'blob'}
      let url = 'AdHocFinancialReport'
      if (type == 'pdf') {
        url += '/pdf'
      }
      this.$axios.post(url, this.searchParams, headers).then(
        (res) => {
          const content = res.headers['content-type']
          const dispo = res.headers['content-disposition']
          var filename = ''
          if (dispo && dispo.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            var matches = filenameRegex.exec(dispo)
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '')
            }
          }
          download(res.data, filename, content)
        },
        (error) => {
          this.handleError(error)
          // this.error = error
          // this.displayError = true
          console.error(error)
        }
      )
    },

    bounce: _.debounce((self) => {
      self.searchReports()
    }, 2000),

    entitySearchBounce: _.debounce((self) => {
      self.searchEntities()
    }, 500),
    includedWithEntitySearchBounce: _.debounce((self) => {
      self.searchIncludedWithEntities()
    }, 500),
    cpaEntitySearchBounce: _.debounce((self) => {
      self.searchCpaEntities()
    }, 500),

    showColumn(name) {
      return this.headers.find((h) => h.value === name).visible
    },
    selectQuery() {
      if (this.selectedQuery && this.selectedQuery.Query) {
        let query = JSON.parse(this.selectedQuery.Query)
        this.pagination.descending =
          query.sortDirection == 'desc' ? true : false
        this.pagination.sortBy = query.sortProperty || 'DateReceived'
        this.searchText = query.searchText || ''
        this.selectedFields = query.includeFields || []
        this.entity = query.entity || []
        this.includedWithEntity = query.includedWithEntity || []
        this.cpaEntity = query.cpaEntity || []
        this.entityStatus = query.entityStatus || ['Active']
        this.entityTypes = query.entityTypes || []
        this.reportRequired = query.reportRequired || []
        this.onTime = query.onTime || []
        this.reportYear = query.reportYear || []
        this.counties = query.counties || []
        this.censusReports = query.censusReports || []
        this.financialActivity = query.financialActivity || []
        this.queryName = query.queryName || this.selectedQuery.Title
        if (!query.pivotOptions) {
          this.adhocPivotIncludePivot = query.includePivot
          this.adhocPivotFirst = query.pivotFirst
          this.adhocPivotName = query.pivotName
          this.adhocPivotRowFields = query.rowFields || []
          this.adhocPivotColumnFields = query.columnFields || []
          this.adhocPivotExcludeFields = query.excludeFields || []
          this.adhocPivotDataFields = query.dataFields || []
          this.adhocPivotFilterFields = query.filterFields || []
        } else {
          this.adhocPivotIncludePivot = query.pivotOptions.includePivot
          this.adhocPivotFirst = query.pivotOptions.pivotFirst
          this.adhocPivotName = query.pivotOptions.pivotName
          this.adhocPivotRowFields = query.pivotOptions.rowFields || []
          this.adhocPivotColumnFields = query.pivotOptions.columnFields || []
          this.adhocPivotExcludeFields = query.pivotOptions.excludeFields || []
          this.adhocPivotDataFields = query.pivotOptions.dataFields || []
          this.adhocPivotFilterFields = query.pivotOptions.filterFields || []
        }
      } else {
        this.resetQuery()
      }
      if (this.selectedQuery && this.selectedQuery.ID <= 0) {
        //this can be used for additional processing for query presets that are hardcoded in javascript,
      }
      this.$nextTick(() => {
        this.queryIsModified = false
      })
    },
    resetQuery() {
      this.queryName = ''
      this.queryIsModified = false
    },
    saveQuery() {
      var savedQueries = new SavedQueriesFinancialReport()
      savedQueries.UserID = this.currentUser.ID
      savedQueries.Title = this.savedQueriesTitle
      this.queryIsModified = false
      this.queryName = this.savedQueriesTitle
      savedQueries.Query = JSON.stringify(this.searchParams)
      this.createSavedQueriesFinancialReport(savedQueries).then((res) => {
        this.savedQueries.push(res.data)
      })
      //this.user = new User()
      this.modalText = 'Save Query'
      this.dialog = !this.dialog
    },
    callSync() {
      this.isSyncing = true
      const url = `FInancialReport/Sync`
      this.$axios.get(url).then(
        (res) => {
          this.isSyncing = false
          this.toastMessage({message: 'sync complete', color: 'success'})
        },
        (error) => {
          this.handleError(error)
          this.isSyncing = false
          // this.error = error
          // this.displayError = true
          console.error(error)
        }
      )
    },
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>
